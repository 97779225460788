<template>
  <v-app>
    <div>
      <v-container>
        <v-form>
          <v-row>
            <v-col class="my-auto" cols="5">
              <div v-if="!IA_report">
                <v-layout justify-end align-center>
                  <h4 class="my-auto text-right">Contraindication report</h4>
                </v-layout>
              </div>
            </v-col>
            <v-col>
              <v-layout justify-center align-center>
                <v-switch
                  color="primary"
                  :dark="false"
                  hint="switch report"
                  :persistent-hint="true"
                  v-model="IA_report"
                ></v-switch>
              </v-layout>
            </v-col>
            <v-col class="my-auto" cols="5">
              <div v-if="IA_report">
                <v-layout align-center>
                  <h4 class="my-auto text-right">Interaction report</h4>
                </v-layout>
              </div>
            </v-col>
          </v-row>
          <div v-if="!IA_report">
            <v-row class="mx-auto mt-10 mt-0">
              <v-col cols="6">
                <v-textarea
                  outlined
                  label="Intervention"
                  background-color="success"
                  color="warning"
                  v-model="parameterA"
                  clearable
                  filled
                  auto-grow
                  rows="3"
                ></v-textarea>
              </v-col>

              <v-col cols="6">
                <v-textarea
                  outlined
                  label="Contraindication"
                  background-color="success"
                  color="warning"
                  v-model="parameterB"
                  clearable
                  filled
                  auto-grow
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>

          <div v-if="IA_report">
            <v-row class="mx-auto mt-10 mt-0">
              <v-col cols="6">
                <v-textarea
                  outlined
                  label="Compound A"
                  background-color="success"
                  color="warning"
                  v-model="parameterA"
                  clearable
                  filled
                  auto-grow
                  rows="3"
                ></v-textarea>
              </v-col>

              <v-col cols="6">
                <v-textarea
                  outlined
                  label="Compound B"
                  background-color="success"
                  color="warning"
                  v-model="parameterB"
                  clearable
                  filled
                  auto-grow
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-form>

        <v-container v-if="!IA_report" class="text-center mb-10">
          <v-btn
            :disabled="!(parameterA && parameterB)"
            elevation="2"
            @click="generateQuery"
            color="accent"
          >
            <p
              class="mx-auto my-auto text-btn"
              :class="
                !(parameterA && parameterB)
                  ? 'text-disabled'
                  : 'text-primary'
              "
            >
              Generate Pubmed Query
            </p>
          </v-btn>
        </v-container>

        <v-container v-if="IA_report" class="text-center mb-10">
          <v-btn
            :disabled="!(parameterA && parameterB)"
            elevation="2"
            @click="generateQuery"
            color="accent"
          >
            <p
              class="mx-auto my-auto text-btn"
              :class="
                !(parameterA && parameterB) ? 'text-disabled' : 'text-primary'
              "
            >
              Generate Pubmed Query
            </p>
          </v-btn>
        </v-container>

        <v-form v-if="!loadingQuery">
          <v-row class="mx-auto mt-0">
            <v-col cols="12">
              <v-textarea
                label="Pubmed Query"
                background-color="accent"
                color="warning"
                @input="resetSubmitButton"
                @click="resetSubmitButton"
                v-model="pubmedQuery"
                clearable
                filled
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-container v-else class="text-center mb-10">
          <vue-loading
            style="color: black"
            spinner="three-bounce"
          ></vue-loading>
          <p class="p-black">Generating query...</p>
        </v-container>

        <v-container v-if="!loadingQuery" class="text-center mb-10">
          <v-btn
            :disabled="!pubmedQuery || isSubmitted"
            elevation="2"
            @click="submitForAnalysis"
            color="primary"
          >
            {{ isSubmitted ? "Submitted!" : submitButtonText }}
          </v-btn>
        </v-container>

        <template>
          <v-container class="pt-10">
            <v-card>
              <v-card-title class="primary">
                <h4 class="text-success">Query history</h4>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="search"
                  color="white"
                  single-line
                  hide-details
                  class="custom-input"
                >
                </v-text-field>
              </v-card-title>
              <v-data-table
                multi-sort
                :loading="loadingQueries"
                loading-text="Loading... Please wait"
                :search="search"
                :headers="headersQueries"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="queries"
                :items-per-page="10"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 50],
                }"
                color="info"
                class="elevation-1"
              >
                <template v-slot:item="{ item }">
                  <tr class="p-table">
                    <td>
                      <router-link
                        :to="{ path: '/queryViewer', query: { qid: item.id } }"
                      >
                        <p>
                          {{ truncate(item.query) }}
                        </p>
                      </router-link>
                    </td>
                    <td>{{ item.type | formatType }}</td>
                    <td>{{ item.timestamp | formatDateTime }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.totalDocs ? item.totalDocs : "NA" }}</td>
                    <td>{{ item.completed | makeYesNo }}</td>
                    <td>
                      <v-btn
                        @click="delete_selected(item.id)"
                        elevation="3"
                        class="ml-3 mb-3 mt-0 mr-0"
                        small
                        color="secondary"
                      >
                        <p class="mx-auto my-auto">delete</p>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </template>
      </v-container>
    </div>
  </v-app>
</template>

<style>
.custom-input label {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}

.custom-input .v-input__slot input {
  color: #ffffff !important;
}

.custom-input .v-input__slot::before {
  border-color: #ffffff !important;
}

.custom-input .v-input__slot::after {
  border-color: #ffffff !important;
}

.custom-input .v-input__slot:not(.v-input__slot--active):before {
  border-bottom-color: #ffffff !important;
}

.custom-input .v-input__slot.v-input__slot--active::after {
  border-bottom-color: #ffffff !important;
}

.custom-input .v-input__icon--append .v-icon {
  color: #ffffff !important;
}
</style>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import VueLoading from "vue-simple-loading";

export default {
  components: { VueLoading },
  name: "Queries",
  data() {
    return {
      IA_report: false,
      parameterA: "",
      parameterB: "",
      isSubmitted: false,
      submitButtonText: "Submit for analysis",
      sortBy: ["timestamp", "uid"],
      sortDesc: [true, false],
      search: "",
      alert: false,
      headersQueries: [
        {
          text: "Query",
          align: "start",
          value: "query",
        },
        {
          text: "Report type",
          value: "type",
        },
        {
          text: "Date and time",
          value: "timestamp",
        },
        {
          text: "Submitted by",
          value: "uid",
        },
        {
          text: "PubMed results",
          value: "totalDocs",
        },
        {
          text: "Query completed?",
          value: "completed",
          filterable: false,
        },
        {
          text: "Delete query",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["queries", "loadingQueries", "userProfile", "loadingQuery"]),
    ...mapGetters(["getPubmedQuery"]),
    pubmedQuery: {
      get() {
        return this.getPubmedQuery ? this.getPubmedQuery : "";
      },
      set(value) {
        this.setPubmedQuery(value);
      },
    },
  },
  methods: {
    ...mapActions([
      "loadQueries",
      "submitQuery",
      "deleteSelectedQuery",
      "makeQuery",
    ]),
    ...mapMutations(["setPubmedQuery"]),
    generateQuery() {
      this.makeQuery({
        parameters: [this.parameterA, this.parameterB],
        type: this.IA_report ? "IA" : "CI",
      });
    },
    newQuery() {
      this.submitQuery({
        query: this.pubmedQuery,
        parameters: [this.parameterA, this.parameterB],
        type: this.IA_report ? "IA" : "CI",
      });
    },
    submitForAnalysis() {
      this.isSubmitted = true;
      this.newQuery();
    },
    resetSubmitButton() {
      this.isSubmitted = false;
    },
    truncate(text, limit = 50, suffix = "...") {
      if (text.length > limit) {
        return text.substring(0, limit) + suffix;
      } else {
        return text;
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        this.alert = true;
      } else {
        return true;
      }
    },
    delete_selected(qid) {
      this.deleteSelectedQuery(qid);
    },
    hide_alert() {
      window.setInterval(() => {
        this.alert = false;
      }, 3000);
    },
  },
  mounted() {
    this.loadQueries();
    if (alert) {
      this.hide_alert();
    }
  },
};
</script>
